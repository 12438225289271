import React from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";

export default function MatchProcessing({ profileId }: { profileId: string }) {
  const { t } = useTranslation();
  return (
    <SharedMatchLayout
      match
      title={t("fortnite:match.matchProcessing", "Processing game...")}
      underTitle={
        <div className="color-shade2">
          {t(
            "fortnite:match.matchProcessingDescription",
            "Please come back in a few minutes",
          )}
        </div>
      }
      image={FALLBACK_IMAGE_URL}
      imageLink={`/fortnite/profile/${profileId}`}
    >
      <MainColumnsContainer>
        <div className="span-1">
          <div className="relative">
            <Card loading style={{ height: "calc(var(--sp-46) * 2)" }} />
            <div className="absolute-center">
              <BlitzLogoLoading />
            </div>
          </div>
        </div>
        <div className="span-2">
          <div className="relative">
            <Card loading style={{ height: "var(--sp-48)" }} />
            <div className="absolute-center">
              <BlitzLogoLoading />
            </div>
          </div>
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}
