import React from "react";
import { useTranslation } from "react-i18next";

type Quest = {
  description: string;
  xpToEarn: number;
  amount: number;
  total: number;
};

// This feature has been effectively put on hold for MLP
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Quests({ quests }: { quests: Array<Quest> }) {
  const { t } = useTranslation();
  return (
    <div>
      {t("common:quests", "Quests")}
      {quests.map((quest) => (
        <div key={quest.description}>{quest.description}</div>
      ))}
    </div>
  );
}

export default function Main() {
  return null;
}
