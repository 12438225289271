import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { FORTNITE_QUEUES } from "@/game-fortnite/constants/queues.mjs";
import type { PlayerMatchBE } from "@/game-fortnite/models/match-player.mjs";
import type { MatchQuery } from "@/game-fortnite/models/match-query.mjs";
import type { Matchlist } from "@/game-fortnite/models/matchlist.mjs";
import getLastNMatches, {
  getRecentAvg,
} from "@/game-fortnite/utils/get-last-n-matches.mjs";
import AccoladeHeadshotIcon from "@/inline-assets/accolade-crosshair.svg";
import HeavyAmmoIcon from "@/inline-assets/fn-heavy-ammo.svg";
import {
  Accolade as AccoladeShared,
  AccoladeHeader as AccoladeHeaderShared,
  DataBars,
} from "@/shared/Accolades.jsx";
import { calcRate, toFixedNumber } from "@/util/helpers.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Accolade({
  profileId,
  match,
}: {
  profileId: string;
  match: MatchQuery;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  // @ts-ignore
  const matches: { [gameId: string]: MatchQuery } = state.fortnite.matches;
  const matchlist: Record<string, Matchlist> = useMemo(
    () => state.fortnite.matchlists[profileId] ?? {},
    [profileId, state.fortnite.matchlists],
  );
  const lastNMatches: Array<MatchQuery> = useMemo(
    () => getLastNMatches(matches, matchlist, match),
    [matches, matchlist, match],
  );
  const findPlayerFn = (i: PlayerMatchBE): boolean =>
    i.player.accountId === profileId;
  const player: PlayerMatchBE | undefined =
    match.playerMatches.find(findPlayerFn);
  const playerKills = player?.kills ?? 0;
  const playerDamageDealt = player?.damageDealt ?? 0;
  const { count, recentAvgs } = getRecentAvg(profileId, lastNMatches);

  const didBetterKills = playerKills >= (recentAvgs.kills ?? 0);
  const didBetterDamage = playerDamageDealt >= (recentAvgs.damageDealt ?? 0);
  const playerKillsRate =
    playerKills && recentAvgs.kills
      ? calcRate(playerKills, recentAvgs.kills)
      : Math.max(playerKills, recentAvgs.kills ?? 0) + 1;
  const playerKillsPercentile = formatToPercent(
    language,
    Math.abs(didBetterKills ? playerKillsRate - 1 : 1 - playerKillsRate),
    { max: 1 },
  );
  const playerDamageRate =
    playerDamageDealt && recentAvgs.damageDealt
      ? calcRate(playerDamageDealt, recentAvgs.damageDealt)
      : Math.max(playerDamageDealt, recentAvgs.damageDealt ?? 0) + 1;
  const playerDamagePercentile = formatToPercent(
    language,
    Math.abs(didBetterDamage ? playerDamageRate - 1 : 1 - playerDamageRate),
    { max: 1 },
  );
  const queue = Object.getOwnPropertySymbols(FORTNITE_QUEUES).find(
    (s) => FORTNITE_QUEUES[s].key === match.mode,
  );

  return (
    <>
      <AccoladeShared
        icon={<AccoladeHeadshotIcon />}
        didBetter={didBetterKills}
        defaultShow
      >
        <AccoladeHeaderShared
          title={t("common:elims", "Elims")}
          info={
            <Trans
              t={t}
              i18nKey={
                didBetterKills
                  ? "fps:accolades.elimsBetter"
                  : "fps:accolades.elimsWorse"
              }
              defaults={
                didBetterKills
                  ? "Your elims are <highlight>{{value}}</highlight> better than your {{queue}} recent {{matches}} avg."
                  : "Your elims are <highlight>{{value}}</highlight> worse than your {{queue}} recent {{matches}} avg."
              }
              values={{
                value: playerKillsPercentile,
                queue: (queue
                  ? t(...FORTNITE_QUEUES[queue].t)
                  : match.mode
                ).toUpperCase(),
                matches: count,
              }}
              components={{ highlight: <span /> }}
            />
          }
          handleClick={() => {}}
        />
        <DataBars
          title={undefined}
          data={[playerKills, toFixedNumber(recentAvgs.kills ?? 0, 1)]}
          legend1={t("common:thisMatch", "This Match")}
          legend2={t("common:stats.recentAvg", "Recent Avg.")}
          didBetter={didBetterKills}
        />
      </AccoladeShared>
      {Boolean(count && playerDamageDealt) && (
        <AccoladeShared
          icon={<HeavyAmmoIcon />}
          didBetter={didBetterDamage}
          defaultShow
        >
          <AccoladeHeaderShared
            title={t("common:damage", "Damage")}
            info={
              <Trans
                t={t}
                i18nKey={
                  didBetterDamage
                    ? "fps:accolades.damageDealtMore"
                    : "fps:accolades.damageDealtLess"
                }
                defaults={
                  didBetterDamage
                    ? "You dealt <highlight>{{value}} more</highlight> damage than you normally do in {{gameMode}}"
                    : "You dealt <highlight>{{value}} less</highlight> damage than you normally do in {{gameMode}}"
                }
                values={{
                  value: playerDamagePercentile,
                  gameMode: (queue
                    ? t(...FORTNITE_QUEUES[queue].t)
                    : match.mode
                  ).toUpperCase(),
                }}
                components={{ highlight: <span /> }}
              />
            }
            handleClick={() => {}}
          />
          <DataBars
            title={t("fortnite:stats.damageDealt", "Damage Dealt")}
            data={[
              playerDamageDealt,
              toFixedNumber(recentAvgs.damageDealt ?? 0, 1),
            ]}
            legend1={t("common:thisMatch", "This Match")}
            legend2={t(
              "common:recentMatchCountAvg",
              "Recent {{ count, number }} Avg.",
              {
                count: lastNMatches.length,
              },
            )}
            didBetter={didBetterDamage}
          />
        </AccoladeShared>
      )}
    </>
  );
}
