import type { MatchCore } from "@/game-fortnite/models/game-events.mjs";
import type { Item } from "@/game-fortnite/models/item.mjs";
import type { MatchQuery } from "@/game-fortnite/models/match-query.mjs";

export function getItemsUsed(
  profileId: string,
  match: MatchQuery | Error | undefined,
): Array<Item> {
  if (!match || match instanceof Error) return [];
  const playerMatch = match.playerMatches.find(
    (i) => i.player.accountId === profileId,
  );
  if (!playerMatch) return [];
  return playerMatch.itemQuantities.map((i) => i.item);
}

export function getWeaponsUsedInGame(match: MatchCore | null): Array<Item> {
  // Todo: There is still discussion whether or not this should be released, leaving this here until we need it
  const isPacketsReceiving = Boolean(match?.matchLive);
  if (!isPacketsReceiving) return [];
  return [];
}
